<template>
  <div>
    <c-search-box>
      <template slot="search">
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <!-- 사업장 -->
          <c-plant type="search" name="plantCd" v-model="searchParam.plantCd" />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <c-datepicker
            required
            default="today"
            type="year"
            label="년도"
            name="year"
            v-model="searchParam.year"
          />
        </div>
      </template>
    </c-search-box>
    <c-table
      ref="table"
      title="실적조사표"
      :merge="grid.merge"
      :columns="grid.columns"
      :data="grid.data"
    >
      <!-- 버튼 영역 -->
      <template slot="table-button">
        <q-btn-group outline >
          <c-btn label="검색" icon="search" @btnClicked="getList" />
        </q-btn-group>
      </template>
    </c-table>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
export default {
  name: 'inoutStatisticalInvestigation',
  components: {
  },
  props: {
    height: {
      type: String,
      default: function() {
        return '';
      },
    },
  },
  data() {
    return {
      searchParam: {
        plantCd: null,
        year: '',
      },
      grid: {
        merge: [
          { index: 0, colName: 'materialCd' },
          { index: 1, colName: 'materialCd' },
          { index: 2, colName: 'materialCd' },
          { index: 3, colName: 'materialCd' },
          { index: 4, colName: 'materialCd' },
          { index: 5, colName: 'materialCd' },
          { index: 6, colName: 'materialCd' },
          { index: 7, colName: 'materialCd' },
          { index: 8, colName: 'materialCd' },
          { index: 9, colName: 'materialCd' },
          { index: 10, colName: 'materialCd' },
          { index: 18, colName: 'materialCd' },
          { index: 19, colName: 'materialCd' },
          { index: 20, colName: 'materialCd' },
          { index: 21, colName: 'materialCd' },
          { index: 22, colName: 'materialCd' },
          { index: 23, colName: 'materialCd' },
        ],
        columns: [
          {
            name: 'materialCd',
            field: 'materialCd',
            label: '자재코드',
            align: 'center',
            style: 'width:120px',
            sortable: false,
          },
          {
            name: 'mdmChemMaterialName',
            field: 'mdmChemMaterialName',
            label: '품명',
            align: 'left',
            style: 'width:200px',
            sortable: false,
          },
          {
            name: 'materialName',
            field: 'materialName',
            label: '자재명<br/>(SAP)',
            align: 'center',
            style: 'width:90px',
            sortable: false,
          },
          {
            name: 'deptName',
            field: 'deptName',
            label: '사용부서',
            align: 'center',
            style: 'width:90px',
            sortable: false,
          },
          {
            name: 'processName',
            field: 'processName',
            label: '사용공정',
            align: 'center',
            style: 'width:70px',
            sortable: false,
          },
          {
            name: 'usageName',
            field: 'usageName',
            label: '용도',
            align: 'center',
            style: 'width:70px',
            sortable: false,
          },
          {
            name: 'inAmountBefore',
            field: 'inAmountBefore',
            label: '입고수량',
            align: 'center',
            style: 'width:65px',
            sortable: true,
          },
          {
            name: 'inUnitName',
            field: 'inUnitName',
            label: '입고단위<br/>(SAP)',
            align: 'center',
            style: 'width:65px',
            sortable: true,
          },
          {
            name: 'inConversonFactor',
            field: 'inConversonFactor',
            label: '환산계수',
            align: 'center',
            style: 'width:65px',
            sortable: true,
          },
          {
            name: 'inConversonUnitName',
            field: 'inConversonUnitName',
            label: '환산단위',
            align: 'center',
            style: 'width:65px',
            sortable: true,
          },
          {
            name: 'outAmountOri',
            field: 'outAmountOri',
            label: '출고수량',
            align: 'center',
            style: 'width:65px',
            sortable: true,
          },
          {
            name: 'chemNmEn',
            field: 'chemNmEn',
            label: '성분명',
            align: 'left',
            style: 'width:200px',
            sortable: false,
          },
          {
            name: 'casNo',
            field: 'casNo',
            label: 'CAS No.',
            align: 'center',
            style: 'width:100px',
            sortable: false,
          },
          {
            name: 'limitLow',
            field: 'limitLow',
            label: '최소함량(%)',
            align: 'right',
            style: 'width:80px',
            sortable: false,
          },
          {
            name: 'limitHigh',
            field: 'limitHigh',
            label: '최대함량(%)',
            align: 'right',
            style: 'width:80px',
            sortable: false,
          },
          {
            name: 'limitRepval',
            field: 'limitRepval',
            label: '대표함량(%)',
            align: 'right',
            style: 'width:80px',
            sortable: false,
          },
          {
            name: 'specificGravity',
            field: 'specificGravity',
            label: '비중',
            align: 'right',
            style: 'width:80px',
            sortable: false,
          },
          {
            name: 'outAmount',
            field: 'outAmount',
            label: '취급량(KG)',
            align: 'right',
            style: 'width:80px',
            sortable: false,
          },
          {
            name: 'deliveryVendorName',
            field: 'deliveryVendorName',
            label: '공급사',
            align: 'center',
            style: 'width:80px',
            sortable: false,
          },
          {
            name: 'mfgVendorName',
            field: 'mfgVendorName',
            label: '제조사',
            align: 'center',
            style: 'width:80px',
            sortable: false,
          },
          {
            name: 'address',
            field: 'address',
            label: '제조사주소',
            align: 'center',
            style: 'width:80px',
            sortable: false,
          },
          {
            name: 'phoneNo',
            field: 'phoneNo',
            label: '제조사전화',
            align: 'center',
            style: 'width:80px',
            sortable: false,
          },
          {
            name: 'preMdmChemMaterialId',
            field: 'preMdmChemMaterialId',
            label: '개정이력',
            align: 'center',
            style: 'width:80px',
            sortable: false,
          },
          {
            name: 'remarks',
            field: 'remarks',
            label: '비고',
            align: 'center',
            style: 'width:80px',
            sortable: false,
          },
        ],
        data: [],
      },
      editable: true,
      listUrl: '',
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      this.listUrl = selectConfig.chm.inout.statistics.url;
      // code setting
      // list setting
      this.getList();
    },
    getList() {
      if (!this.searchParam.year) {
        window.getApp.$emit('ALERT', {
          title: '안내 ', /* 안내 */
          message: '검색조건 년도를 지정한 후 이용바랍니다.',
          type: 'warning', // success / info / warning / error
        });
        return;
      }
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = this.searchParam;
      this.$http.request((_result) => {
        this.grid.data = _result.data;
      },);
    },
  }
};
</script>